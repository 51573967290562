@color-black: #290916;
@color-grey: rgba(82, 75, 75, 0.3);
@color0: #edecec;
@color1: rgba(242, 4, 159, 1);
@color2: rgba(4, 218, 242, 1);
@color3: rgba(4, 216, 78, 1);
@color4: rgba(242, 202, 4, 1);
@color5: rgba(242, 135, 4, 1);
@border-radius: 15pt;
.nice-font { font-family: 'Patrick Hand', cursive; }

html,body,#app {
  max-width: 100%;
  margin: 0;
  .nice-font();
  background-color: @color0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.app {
  animation: fadein 0.42s ease-in-out;
  min-height: 100vh;
  background-color: @color0;
  &.correct {
    transition: opacity, background-color 0.5s ease-in-out;
    background-color: rgba(4, 216, 78, 0.4);
  }
  &.settings-page {
    transition: opacity, background-color 0.5s ease-in-out;
    background-color: @color-grey;
  }
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  z-index: 1;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 25pt;
  padding-left: 5pt;
  padding-right: 5pt;
  padding-bottom: 5pt;
  margin-top: 5pt;
  margin-bottom: 5pt;
}


h1 {
  margin: 0pt;
  margin-top: 0pt;
  line-height: 1em;
  text-align: center;
  color: @color1;
  font-size: 4em;
  margin-bottom: 10pt;
  opacity: 1;
  text-shadow: 0pt 0pt 20pt white;
  transition: all 0.8s ease;
  &.correct-star {
    color: @color-black;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: relative;
  }
  &.page-title {
    color: @color-black;
  }
}

.settings-content {
  height: 100%;
  font-size: 1.5em;

  .sound {
    visibility: hidden;
  }

  .sound ~ label {
    display: flex;
    align-items: center;
  }

  .icon(@i) {
    content: '';
    background: url(@i);
    background-size: contain;
    background-repeat: no-repeat;
    height: 2em;
    width: 2em;
    margin: 0px;
    padding: 0px;
    border: none;
  }

  .sound ~ label:before {
    .icon("../assets/close.svg");
  }

  .sound:checked ~ label:before {
    .icon("../assets/sound-on.svg");
  }

  h3 {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  h3:after {
    .icon("../assets/info.svg");
  }
}

.settings-info {
  margin: 0px;
}

.navigaton-button {
  position: absolute;
  top: 25px;
  right: 5px;
  display: inline;
  height: 4em;
  width: 4em;
  margin: 0px;
  padding: 0px;
  border: none;
  animation: appearing 0.62s ease-in-out;
  &.AbcLouPage {
    background: url(../assets/close.svg);
    background-size: cover;
  }
  &.SettingsPage {
    background: url(../assets/settings.svg);
    background-size: cover;
  }
}

.word-image-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  .word-image {
    animation: appearing 0.62s ease-in-out;
    transition: all 0.5s ease;
    width: 100%;
    max-width: 400px;
  }
}

.letters {
  display: flex;
  margin-top: 0pt;
  margin-bottom: 10pt;
  margin-left: 10pt;
  margin-right: 10pt;
  padding: 0pt;
  justify-content: space-around;
  align-items: center;
  width: 350px;
  max-width: 100%;
  height: 80px;
  box-sizing: border-box;
}

.letter-base {
  .nice-font();
  background-color: @color2;
  border-radius: @border-radius;
  border: none;
  color: @color-black;
  font-size: 3em;
  height: 100%;
  max-height: 300pt;
  opacity: 1;
  transition: all 0.5s ease;
  text-align: center;
  max-width: 80pt;
  width: 100%;
  padding: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.reset {
  .letter-base();
  height: 40pt;
  font-size: 1em;
  background-color: @color5;
}

.letter {
  .letter-base();
  transform: translate3d(0, -4pt, 0);
  .shadow(8pt);
  text-shadow: 0pt 0pt 20pt white, 0pt 0pt 2pt white;
  background-color: @color2;

  margin-left: 2.5pt;
  margin-right: 2.5pt;

  &:focus {
    border: solid 2pt @color1;
  }

  &.disabled {
    background-color: @color4;
  }

  &.wrong {
    animation: shake 0.62s cubic-bezier(.36,.07,.19,.97) both;
    background-color: @color5;
    perspective: 1000pt;
  }

  &.wrong, &.disabled, &.unfocused, &.correct {
    transition: background-color 0.5s ease;
    box-shadow: none;
    text-shadow: none;
    transform: translate3d(0, 0, 0);
  }

  &.correct {
    .letter-base();
    background-color: @color3;
    text-align: center;
  }

  &.unfocused {
    .letter-base();
    text-align: center;
    background-color: @color-grey;
    opacity: 0.3;
  }
}

.shadow(@offset) {
  box-shadow: 0 @offset 6pt -6pt black;
}

.attributation {
  display: block;
  height: 10pt;
  color: @color-grey;
}

.alphabet-frequency {
  max-height: 250pt;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@keyframes appearing {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes shake {
  20%, 90% {
    transform: rotate(5deg) translate3d(-1pt, 0, 0);
  }
  
  20%, 80% {
    transform: rotate(-5deg) translate3d(2pt, 0, 0);
  }

  30%, 50%, 70% {
    transform: rotate(5deg) translate3d(-4pt, 0, 0);
  }

  40%, 60% {
    transform: rotate(-5deg) translate3d(4pt, 0, 0);
  }
}
